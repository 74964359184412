import articleContext from "./articleContext";
import React, { useEffect, useState } from "react";
const ArticleState = (props) => {
	const [ArticleData, setArticleData] = useState([]);
	useEffect(() => {
		fetch("https://backend.tror.ai/api/allhtmlArticles")
			.then((res) => res.json())
			.then((data) => {
				let articles = data.articles;
				setArticleData(articles);
			})
			.catch((err) => {
			});
	}, []);
	return (
		<articleContext.Provider
			value={{
				ArticleData,
				setArticleData,
			}}
		>
			{props.children}
		</articleContext.Provider>
	);
};
export default ArticleState;
